<template>
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 20px 0px;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/police' }">政策法规</el-breadcrumb-item>
			<el-breadcrumb-item>条法类别</el-breadcrumb-item>
		</el-breadcrumb>
		<table class="common">
			<tr>
				<td class="blodTd">
					<span :class="form.law_type == law_type_options[0]['value']?'selected':''"
						@click="goType(law_type_options[0]['value'])">
						{{law_type_options[0]['label']}}
						({{typeCount[law_type_options[0]['value']]}})
					</span>
				</td>
				<td v-for="(item) in law_type_options[0]['options']" :key="item.value" @click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
				<td></td>
			</tr>
			<tr>
				<td class="blodTd">
					<span :class="form.law_type == law_type_options[1]['value']?'selected':''"
						@click="goType(law_type_options[1]['value'])">
						{{law_type_options[1]['label']}}
						({{typeCount[law_type_options[1]['value']]}})
					</span>
				</td>
				<td v-for="(item) in law_type_options[1]['options']" :key="item.value" @click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
				<td></td>
				<td></td>
			</tr>
			<tr>
				<td class="blodTd">
					<span :class="form.law_type == law_type_options[2]['value']?'selected':''"
						@click="goType(law_type_options[2]['value'])">
						{{law_type_options[2]['label']}}
						({{typeCount[law_type_options[2]['value']]}})
					</span>
				</td>
				<td v-for="(item) in law_type_options[2]['options']" :key="item.value" @click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
				<td></td>
			</tr>
			<tr>
				<td class="blodTd">
					<span :class="form.law_type == law_type_options[3]['value']?'selected':''"
						@click="goType(law_type_options[3]['value'])">
						{{law_type_options[3]['label']}}
						({{typeCount[law_type_options[3]['value']]}})
					</span>
				</td>
				<td v-for="(item) in law_type_options[3]['options']" :key="item.value" @click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
			</tr>
			<tr>
				<td class="blodTd">
					<span :class="form.law_type == law_type_options[4]['value']?'selected':''"
						@click="goType(law_type_options[4]['value'])">
						{{law_type_options[4]['label']}}
						({{typeCount[law_type_options[4]['value']]}})
					</span>
				</td>
				<td v-for="(item) in law_type_options[4]['options'].slice(0,5)" :key="item.value"
					@click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
			</tr>
			<tr>
				<td class="blodTd"></td>
				<td v-for="(item) in law_type_options[4]['options'].slice(5,10)" :key="item.value"
					@click="goType(item['value'])">
					<span
						:class="form.law_type == item['value']?'selected':''">{{item.label}}({{typeCount[item['value']]}})</span>
				</td>
			</tr>
		</table>
		<el-table :data="tableData" style="width:100%" border size="small">
			<el-table-column prop="title" label="标题" width="590"></el-table-column>
			<el-table-column prop="code" label="发文字号" width="140"></el-table-column>
			<el-table-column prop="issue_date" label="颁布日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_date != '0000-00-00'">{{ scope.row.issue_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="implement_date" label="实施日期" width="90">
				<template slot-scope="scope">
					<span v-if="scope.row.implement_date != '0000-00-00'">{{ scope.row.implement_date}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="issue_company" label="颁布单位" width="110">
				<template slot-scope="scope">
					<span v-if="scope.row.issue_company">{{ issue_companys[scope.row.issue_company- 1].name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="danger" style="background-color:#cf1322;" size="mini" @click="goTo(scope)">查看详情
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<p style="margin-top: 15px;">
			<el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
				layout="total,  prev, pager, next, jumper" :total="tableCount" style="margin-top: 10px;">
			</el-pagination>
		</p>

	</div>
</template>
<script>
	export default {
		name: 'policeType',
		data() {
			return {
				form: {
					title: '',
					law_type: '',
					issue_dates: [],
					page: 1,
					pageSize: 10,
				},
				tableData: [],
				tableCount: 0,
				typeCount: []
			}
		},
		mounted() {
			this.form.law_type = this.$route.query.law_type;
			//this.$route.query.str
			this.search();
		},
		methods: {
			search() {
				var that = this;
				this.$axios.post(this.baseUrl + 'index/pdicies/pdiciesList', this.form).then(response => {
					that.tableData = response.data.list;
					that.tableCount = response.data.count;
					that.typeCount = response.data.typeCount;
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			handleCurrentChange(val) {
				this.form.page = val;
				this.search();
			}, //翻页
			goType(law_type) {
				this.form.law_type = law_type;
				this.form.page = 1;
				this.search();
				//location.href = '/policeType?law_type='+law_type;
				this.$router.push({
					path: '/policeType',
					query: {
						law_type: law_type
					}
				})
			},
			goTo(scope) {
				this.$router.push({
					path: '/policeContent',
					query: {
						id: scope.row.id
					}
				})
			},
		}
	}
</script>

<style scoped>
	.common {
		font-size: 14px;
		text-align: left;
		color: #cf1322;
		margin-bottom: 10px;
	}

	.common td {
		padding: 1px 10px;
	}

	.common td span {
		cursor: pointer;
		padding: 3px 10px;
	}
	.common td span:hover{text-decoration:underline;}
	.common td .selected {
		background-color: #cf1322;
		color: #ffffff;
		padding: 3px 10px;
	}

	.common .blodTd {
		font-weight: 550;
	}

	.tableInfo {
		border-collapse: collapse;
		font-size: 14px;
		width: 100%;
	}

	.tableInfo td,
	.tableInfo th {
		padding: 10px;
		border: #DCDFE6 1px solid;
	}

	.tableInfo th {
		background-color: #F2F6FC;
	}
</style>
